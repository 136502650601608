<template>
  <div>
    <NavBar :navSelIndex="-1"></NavBar>
    <div class="package">
      <div class="package_cont">
        <div class="package_deails">
          <b-row class="package_img">
            <b-col class="package_left" sm="12" md="12" lg="12" xl="9">
              <img :src="coverImg" alt="" />
            </b-col>
            <b-col class="package_right" sm="12" md="12" lg="12" xl="3">
              <img @click="coverImg = item.imgUrl" v-for="(item, index) in homeSlide" :key="index" :src="item.imgUrl"
                alt="" />
            </b-col>
          </b-row>
          <div class="package_txt">
            <div class="package_txt_left">
              <span class="package_txt_title">{{
                topDetails.setmeal_name
              }}</span>
              <span class="package_txt_area">{{ topDetails.hurse_type }}</span>
              <!-- <span class="door_model">三房两厅</span> -->
            </div>
            <b-row class="package_txt_right">
              <b-col class="package_txt_details" sm="12" md="12" lg="12" xl="9">
                {{ topDetails.setmeal_introduce }}
              </b-col>
              <b-col class="package_plan" sm="12" md="12" lg="12" xl="3">
                <div class="erW" v-if="topDetails.two_d_id">
                  <div class="erW_in" @click="goErW(topDetails)">
                    <span>二维方案</span>
                    <img src="../../../public/packageDetails/sjmj12.png" alt="" />
                  </div>
                </div>
                <div class="sanW" v-if="topDetails.three_d_url" @mouseenter="qrCodeShow = true"
                  @mouseleave="qrCodeShow = false">
                  <div class="sanW_in" @click="goSanW(topDetails)">
                    <span>三维方案</span>
                    <img src="../../../public/packageDetails/sjmj13.png" alt="" />
                  </div>
                  <div class="Qr_code" v-show="qrCodeShow">
                    <span></span>
                    <img :src="'https://qrcode.jp/qr?q=' + topDetails.three_d_url" alt="" />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="special_election">
            <div class="special_item" v-for="(item, index) in taocanList" :key="index">
              <div class="special_top">
                <img src="../../../public/packageDetails/sjmj11.png" alt="" />
                <span class="space">{{ item.name }}</span>
                <!-- <span class="choose">二选一</span> -->
              </div>
              <div class="special_cont_wrap" v-if="!index">
                <div :class="[item.name == '客厅'?'special_cont_one':'special_cont']" v-for="(inItem, inIndex) in item.list" :key="inIndex">
                  <div class="special_cont_tar">
                    <div class="tar_name">
                      <span class="space_one">{{ inItem.name }}</span>
                      <span class="choose_num">已选{{ inItem.total_amount }}件商品</span>
                    </div>
                    <el-checkbox @change="checkSpace(index, inIndex, inItem)" v-model="inItem.checked"
                      fill="red"></el-checkbox>
                  </div>

                  <div class="template" v-if="inItem.list.length == 4">
                    <div class="goods_list">
                      <div class="goods_item" v-for="(it, ind) in inItem.list" :key="ind"
                        @click="goGoodsDetails(it.goodsId)">
                        <div class="pic">
                          <img :src="it.goodsImg" alt="" />
                        </div>
                        <div class="goods_amount">
                          <span class="goods_name">{{
                            it.twoClassificationName
                          }}</span>
                          <div class="goods_num">
                            <span class="reduce" @click.stop="reduceNum(it, inItem)">-</span>
                            <span class="num">{{ it.goodsNumber }}</span>
                            <span class="add" @click.stop="addNum(it, inItem)">+</span>
                          </div>
                        </div>
                        <div class="price">
                          <!-- <span class="bar_price">￥{{ it.bar_price }}</span> -->
                          <span class="preferential_price">e家价 ￥{{ it.price }}</span>
                        </div>
                        <span class="model">{{ it.goodsParameter }}</span>
                        <!-- <span class="size">尺寸：W2060*D770*H670mm（三人位）</span>
                      <span class="material"
                        >材质：白蜡木实木框架+棉麻布+高密度海棉</span
                      > -->
                      </div>
                    </div>
                  </div>
                  <div class="template" v-if="inItem.list.length == 3">
                    <div class="goods_list">
                      <div :class="!ind ? 'goods_item big' : 'goods_item'" v-for="(it, ind) in inItem.list" :key="ind"
                        @click="goGoodsDetails(it.goodsId)">
                        <img :src="it.goodsImg" alt="" />
                        <div class="goods_amount">
                          <span class="goods_name">{{
                            it.twoClassificationName
                          }}</span>
                          <div class="goods_num">
                            <span class="reduce" @click.stop="reduceNum(it, inItem)">-</span>
                            <span class="num">{{ it.goodsNumber }}</span>
                            <span class="add" @click.stop="addNum(it, inItem)">+</span>
                          </div>
                        </div>
                        <div class="price">
                          <!-- <span class="bar_price">￥{{ it.bar_price }}</span> -->
                          <span class="preferential_price">e家价 ￥{{ it.price }}</span>
                        </div>
                        <span class="model">{{ it.goodsParameter }}</span>
                        <!-- <span class="size">尺寸：W2060*D770*H670mm（三人位）</span>
                      <span class="material"
                        >材质：白蜡木实木框架+棉麻布+高密度海棉</span
                      > -->
                      </div>
                    </div>
                  </div>
                  <div class="template" v-if="inItem.list.length <= 2">
                    <div class="goods_list">
                      <div class="goods_item" v-for="(it, ind) in inItem.list" :key="ind"
                        @click="goGoodsDetails(it.goodsId)">
                        <img :src="it.goodsImg" alt="" />
                        <div class="goods_amount">
                          <span class="goods_name">{{
                            it.twoClassificationName
                          }}</span>
                          <div class="goods_num">
                            <span class="reduce" @click.stop="reduceNum(it, inItem)">-</span>
                            <span class="num">{{ it.goodsNumber }}</span>
                            <span class="add" @click.stop="addNum(it, inItem)">+</span>
                          </div>
                        </div>
                        <div class="price">
                          <!-- <span class="bar_price">￥{{ it.bar_price }}</span> -->
                          <span class="preferential_price">e家价 ￥{{ it.price }}</span>
                        </div>
                        <span class="model">{{ it.goodsParameter }}</span>
                        <!-- <span class="size">尺寸：W2060*D770*H670mm（三人位）</span>
                      <span class="material"
                        >材质：白蜡木实木框架+棉麻布+高密度海棉</span
                      > -->
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="special_cont_wrap" v-else>
                <div class="special_cont" v-for="(inItem, inIndex) in item.list" :key="inIndex">
                  <div class="special_cont_tar">
                    <div class="tar_name">
                      <span class="space_one">{{ inItem.name }}</span>
                      <span class="choose_num">已选{{ inItem.total_amount }}件商品</span>
                    </div>
                    <el-checkbox @change="checkSpace(index, inIndex, inItem)" v-model="inItem.checked"
                      fill="red"></el-checkbox>
                  </div>
                  <div class="goods_list">
                    <div class="goods_item" v-for="(it, ind) in inItem.list" :key="ind"
                      @click="goGoodsDetails(it.goodsId)">
                      <img :src="it.goodsImg" alt="" />
                      <div class="goods_amount">
                        <span class="goods_name">{{
                          it.twoClassificationName
                        }}</span>
                        <div class="goods_num">
                          <span class="reduce" @click.stop="reduceNum(it, inItem)">-</span>
                          <span class="num">{{ it.goodsNumber }}</span>
                          <span class="add" @click.stop="addNum(it, inItem)">+</span>
                        </div>
                      </div>
                      <div class="price">
                        <!-- <span class="bar_price">￥{{ it.bar_price }}</span> -->
                        <span class="preferential_price">e家价 ￥{{ it.price }}</span>
                      </div>
                      <span class="model">{{ it.goodsParameter }}</span>
                      <!-- <span class="size">尺寸：W2060*D770*H670mm（三人位）</span>
                    <span class="material"
                      >材质：白蜡木实木框架+棉麻布+高密度海棉</span
                    > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="FengFu_district">
            <div class="FengFu_title">
              <span class="line_left"></span>
              <span class="FengFu_name">丰富区</span>
              <span class="line_right"></span>
            </div>
            <div class="goods_list">
              <div class="goods_item" v-for="(item, index) in fengFuList" :key="index"
                @click="goGoodsDetails(item.goods_id)">
                <img :src="item.goods_img" alt="" />
                <div class="goods_amount">
                  <span class="goods_name">{{
                    item.two_classification_name
                  }}</span>


                  <el-checkbox @click.native.stop @change="FengFuChecked(item)" v-model="item.checked"></el-checkbox>
                  <div class="goods_num">
                  <span class="reduce" @click.stop="reduceNumFengFu(item, fengFuList)">-</span>
                  <span class="num">{{ item.goods_number }}</span>
                  <span class="add" @click.stop="addNumFengFu(item, fengFuList)">+</span>
                </div>
                </div>
                
                <div class="price">
                  <span class="bar_price">￥{{ item.price }}</span>
                  <span class="preferential_price">优惠价 ￥{{ item.discountPrice }}</span>
                </div>
                <span class="model">{{ item.goods_parameter }}</span>
                <!-- <span class="size">尺寸：W2060*D770*H670mm（三人位）</span>
              <span class="material"
                >材质：白蜡木实木框架+棉麻布+高密度海棉</span
              > -->
              </div>
            </div>
            <!-- <div class="add_goods" @click="mallShowTop">
            <span>添加更多商品</span>
          </div> -->
          </div>
        </div>
        <div class="package_bottom">
          <div class="look_img" v-show="lookImgShow">
            <div class="img_list">
              <div class="img_item" v-for="(item, index) in img" :key="index">
                <div class="img_item_bar" v-if="item.length > 0">
                  <img class="goods_img" v-for="(it, ind) in item" :key="ind" :src="it.imgUrl" alt="" />
                  <!-- <img
                  v-if="index !== 0 && index !== 2 && index !== 3"
                  class="goods_del"
                  src="../../../public/packageDetails/sjmj14.png"
                  @click="productDel(item,index)"
                  alt=""
                /> -->
                </div>
              </div>
            </div>
            <img @click="lookImgShow = !lookImgShow" class="look_Img_Hid" src="../../../public/packageDetails/sjmj14.png"
              alt="" />
          </div>
          <div class="total_price_information">
            <div class="number" @click="lookImgShow = !lookImgShow" title="查看已选商品">
              <img src="../../../public/packageDetails/sjmj29.png" alt="" />
              <span class="number_txt">已选商品</span>
              <span class="num">{{ sum }}</span>
              <span class="number_txt">件</span>
            </div>
            <div class="original">
              <span class="original_name">套餐原价：</span>
              <span class="original_price">¥ {{ totalPrice }}.00</span>
            </div>
            <div class="preferential">
              <span class="preferential_name">优惠：</span>
              <span class="preferential_price">- ¥ {{ totalPrice - paidPrice }}.00</span>
            </div>
            <div class="actual">
              <span class="actual_name">实际支付：</span>
              <span class="actual_price">¥ {{ paidPrice }}.00</span>
            </div>
            <div class="operation">
              <span class="export_list" @click="submitOrders('dc')">导出清单</span>
              <span class="Submit_orders" @click="submitOrders('tj')">提交订单</span>
            </div>
          </div>
        </div>
      </div>
      <mall @changeMall="changeMall" @confirmGoods="confirmGoods" :mallShow="maShow" :dialogFormSwitch="dialogFormSwitch">
      </mall>
    </div>
    <Right></Right>
    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import Right from "@/components/Right.vue";
import Footer from "@/components/Footer.vue";
import mall from "@/components/mall";
import dump from "@/util/dump";
import url from "@/api/config";
export default {
  components: {
    NavBar,
    Right,
    Footer,
    mall,
  },
  data() {
    return {
      mallShow: false,
      lookImgShow: true, //查看已选商品是否显示
      setmeal_id: "", //套餐Id
      topDetails: "", //套餐详情
      coverImg: "", //套餐封面图
      homeSlide: [], //套餐图列表
      sum: 0, //已选商品总数
      totalPrice: 0, //套餐原价
      paidPrice: 0,//支付价格
      taocanList: [], //空间列表
      fengFuList: [], //丰富区商品
      discount: "1", //套餐折扣
      ids: [], //已选产品id
      number: [], //已选产品数量
      img: [], //已选产品图片
      qrCodeShow: false,
      goodsList: [],
      dialogFormSwitch: false
    };
  },
  mounted() {
    this.setmeal_id = this.$route.query.setmeal_id;
    this.packTopDetails();
    this.packDetails();
    this.mappPackDetails();
  },
  computed: {
    //实付价格
    // paidPrice() {
    //   return Math.ceil(this.totalPrice * Number(this.discount));
    // },
    maShow() {
      return this.mallShow;
    },
  },
  methods: {
    // productDel(item,index){
    //   console.log(item,index)
    // },
    confirmGoods(item) {
      let perform = 0
      this.fengFuList.forEach((it, ind) => {
        if (it.goods_id == item.goods_id) {
          this.$message({
            message: "请勿重复添加！",
            type: "warning",
          });
          perform += 1
        }
        if (ind == this.fengFuList.length - 1) {
          if (perform == 0) {
            this.fengFuList.push(item);
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.dialogFormSwitch = false
          }
        }
      });
    },
    //去二维方案
    goErW(info) {
      if (info.two_d_id) {
        window.open("/#/twoDimensionalProgramme?id=" + info.two_d_id);
      }
    },
    //去三维方案
    goSanW(info) {
      if (info.three_d_url) {
        window.open(info.three_d_url);
      }
    },
    //去商品详情
    goGoodsDetails(id) {
      window.open("/#/details?good_id=" + id);
    },
    //验证是否登录
    checkToken() {
      let token = localStorage.getItem("token");
      if (token == "" || token == null || token == "null") {
        this.$message({
          message: "请先登录",
          type: "warning",
        });
        location.href = "#/login";
      }
    },
    //提交订单-导出清单
    submitOrders(type) {
      console.log(this.ids);
      console.log(this.number);
      const _self = this;
      _self.$api
        .setmealSettlement({
          setmeal_id: _self.setmeal_id,
          type: 131,
          schemeIs: _self.ids.join(","),
          nums: _self.number.join(","),
          discount: 1,
          goodsList: JSON.stringify(_self.goodsList)
        })
        .then((res) => {
          if (res.status == 103) {
            _self.$message({
              message: res.msg,
              type: "warning",
            });
            location.href = "#/login";
          }
          if (res.status == 100) {
            // uni.showToast({
            // 	title: "提交成功"
            // });
            // uni.navigateTo({
            // 	url: "../confirmOrder/confirmOrder?orderId="+res.data.order_id+'&submitOrderType=2'
            // })
            if (type == 'dc') {
              window.open(url + "/appexpertorder/excelDetails.do?order_id=" + res.data.order_id)
            } else {
              _self.$message({
                message: '提交成功',
                type: 'warning'
              });

              dump.link({
                type: 1,
                link: "/orderSettlement",
                params: {
                  orderid: res.data.order_id
                }
              })
            }

          }
        });
    },
    mallShowTop() {
      this.mallShow = !this.mallShow;
    },
    changeMall(mallShow) {
      this.mallShow = !mallShow;
    },
    //商品数量减少
    reduceNum(it, inItem) {
      const that = this;
      if (it.goodsNumber > 0) {
        if (inItem.checked == true) {
          that.totalPrice -= Number(it.price);
          that.paidPrice -= Number(it.discountPrice)
          inItem.totalAmount--;
          that.sum--;
        }
        it.goodsNumber--;
        that.ids.forEach((ite, i) => {
          console.log("schemeId", it.schemeId)
          if (ite == it.schemeId) {
            that.number[i]--;
          }
          console.log("number", this.number[i])
        });
      }
    },
    //商品数量增加
    addNum(it, inItem) {
      console.log("it---" + JSON.stringify(it) + "inItem---" + JSON.stringify(inItem))
      const that = this;
      if (inItem.checked == true) {
        that.totalPrice += Number(it.price)
        that.paidPrice += Number(it.discountPrice)
        console.log("totalPrice", that.totalPrice);
        inItem.totalAmount++;
        that.sum++;
      }
      it.goodsNumber++;
      that.ids.forEach((ite, i) => {
        console.log("schemeId", it.schemeId)
        console.log("ite", ite)
        if (ite == it.schemeId) {
          that.number[i]++;
        }
      });
    },
    //商品数量减少
    reduceNumFengFu(it, inItem) {
      const that = this;
      if (it.goods_number > 0) {
        if (it.goods_number == 1) {
          this.$message({
            title: '提示',
            message: '商品数量不能小于1',
            type: 'error',
            duration: 2000
          })
          return;
        }
        if (it.checked == true) {
          that.totalPrice -= Number(it.price);
          that.paidPrice -= Number(it.price)
          inItem.totalAmount--;
          that.sum--;
        }

        it.goods_number--;
        that.ids.forEach((ite, i) => {
          console.log("schemeId", it.scheme_id)
          if (ite == it.scheme_id) {
            that.number[i]--;
          }
          console.log("number", it.goods_number)
        });
      }



    },
    //丰富区数量增加
    addNumFengFu(it, inItem) {
      console.log("it---" + JSON.stringify(it) + "inItem---" + JSON.stringify(inItem))
      const that = this;
      if (it.checked == true) {
        that.totalPrice += Number(it.price)
        that.paidPrice += Number(it.price)
        console.log("totalPrice", that.totalPrice);
        inItem.totalAmount++;
        that.sum++;
      }
      it.goods_number++;
      that.ids.forEach((ite, i) => {
        console.log("schemeId", it.scheme_id)
        console.log("ite", ite)
        console.log("i", i)
        if (ite == it.scheme_id) {
          console.log("number", that.number)
          that.number[i]++;
        }
      });
    },
    //选择空间
    checkSpace(index, inIndex, inItem) {
      const that = this;
      that.checkToken();
      // let taocanList = that.taocanList;
      let checkedDef = true;
      console.log("---" + index + "---" + inIndex + "---" + JSON.stringify(inItem))
      // if (index == 0 || index == 2 || index == 3) {
      if (inItem.checked == checkedDef) {
        console.log("img+", that.img)
        // that.img[index] = [];
        inItem.list.map((item) => {
          that.ids.push(item.schemeId);
          that.number.push(item.goodsNumber);
          that.img[index].push({
            scheme_id: item.schemeId,
            imgUrl: item.goodsImg,
          });
          that.totalPrice += Number(item.price) * Number(item.goodsNumber);
          that.paidPrice += Number(item.discountPrice) * Number(item.goodsNumber);
          inItem.totalAmount += Number(item.goodsNumber);
          that.sum += Number(item.goodsNumber);
        });
        // if (inIndex == 0) {
        //   taocanList[index].list[inIndex + 1].list.map((item) => {
        //     that.ids.forEach((ite, i) => {
        //       console.log("item",item)
        //       console.log("inIndex",inIndex+1)
        //       if (ite == item.schemeId) {
        //         that.ids.splice(i, 1);
        //         that.number.splice(i, 1);
        //       }
        //     });
        //     that.totalPrice -= Number(item.price) * Number(item.goodsNumber);
        //     that.paidPrice -= Number(item.discountPrice) * Number(item.goodsNumber);
        //     that.sum -= Number(item.goodsNumber);
        //   });
        //   that.$set(taocanList[index].list[inIndex + 1], "totalAmount", 0);
        //   that.$set(
        //     taocanList[index].list[inIndex + 1],
        //     "checked",
        //     !checkedDef
        //   );
        // }
        //  else {
        //   taocanList[index].list[inIndex - 1].list.map((item) => {
        //     that.ids.forEach((ite, i) => {
        //       if (ite == item.schemeId) {
        //         that.ids.splice(i, 1);
        //         that.number.splice(i, 1);
        //       }
        //     });
        //     that.totalPrice -= Number(item.price) * Number(item.goodsNumber);
        //     that.paidPrice -= Number(item.discountPrice) * Number(item.goodsNumber);
        //     that.sum -= Number(item.goodsNumber);
        //   });
        //   that.$set(taocanList[index].list[inIndex - 1], "totalAmount", 0);
        //   that.$set(
        //     taocanList[index].list[inIndex - 1],
        //     "checked",
        //     !checkedDef
        //   );
        // }
      } else {
        console.log("img-", that.img[index])
        // that.img[index] = [];
        inItem.list.map((item) => {
          that.img[index].forEach((ite, i) => {
            console.log("ite", ite.scheme_id)
            console.log("schemeId", item.schemeId)
            if (ite.scheme_id == item.schemeId) {
              that.img[index].splice(i, 1);
            }
          });
        });
        console.log("ids", that.ids)
        inItem.list.map((item) => {
          that.ids.forEach((ite, i) => {
            if (ite == item.schemeId) {
              that.ids.splice(i, 1);
              that.number.splice(i, 1);
            }
          });
          that.totalPrice -= Number(item.price) * Number(item.goodsNumber);
          that.paidPrice -= Number(item.discountPrice) * Number(item.goodsNumber);
          that.sum -= Number(item.goodsNumber);
        });
        inItem.totalAmount = 0;
        // if (inIndex == 0) {
        //   taocanList[index].list[inIndex + 1].list.map((item) => {
        //     that.ids.push(item.schemeId);
        //     that.number.push(item.goodsNumber);
        //     that.img[index].push({
        //       scheme_id: item.schemeId,
        //       imgUrl: item.goodsImg,
        //     });
        //     that.totalPrice += Number(item.price) * Number(item.goodsNumber);
        //     that.paidPrice += Number(item.discountPrice) * Number(item.goodsNumber);
        //     taocanList[index].list[inIndex + 1].totalAmount += Number(
        //       item.goodsNumber
        //     );
        //     that.sum += Number(item.goodsNumber);
        //   });
        //   that.$set(
        //     taocanList[index].list[inIndex + 1],
        //     "checked",
        //     checkedDef
        //   );
        // }
        //  else {
        //   taocanList[index].list[inIndex - 1].list.map((item) => {
        //     that.ids.push(item.schemeId);
        //     that.number.push(item.goodsNumber);
        //     that.img[index].push({
        //       scheme_id: item.schemeId,
        //       imgUrl: item.goodsImg,
        //     });
        //     that.totalPrice += Number(item.price) * Number(item.goodsNumber);
        //     that.paidPrice += Number(item.discountPrice) * Number(item.goodsNumber);
        //     taocanList[index].list[inIndex - 1].totalAmount += Number(
        //       item.goodsNumber
        //     );
        //     that.sum += Number(item.goodsNumber);
        //   });
        //   that.$set(
        //     taocanList[index].list[inIndex - 1],
        //     "checked",
        //     checkedDef
        //   );
        // }
      }
      // } else {
      //   if (inItem.checked == checkedDef) {
      //     that.img[index] = [];
      //     inItem.list.map((item) => {
      //       that.ids.push(item.schemeId);
      //       that.number.push(item.goodsNumber);
      //       that.img[index].push({
      //         scheme_id: item.schemeId,
      //         imgUrl: item.goodsImg,
      //       });
      //       that.totalPrice += Number(item.price) * Number(item.goodsNumber);
      //       that.paidPrice += Number(item.discountPrice) * Number(item.goodsNumber);
      //       inItem.totalAmount += Number(item.goodsNumber);
      //       that.sum += Number(item.goodsNumber);
      //     });
      //     if (inIndex == 0) {
      //       if (taocanList[index].list[inIndex + 1].checked == !checkedDef) {
      //         return false;
      //       }
      //       taocanList[index].list[inIndex + 1].list.map((item) => {
      //         that.ids.forEach((ite, i) => {
      //           if (ite == item.schemeId) {
      //             that.ids.splice(i, 1);
      //             that.number.splice(i, 1);
      //           }
      //         });
      //         that.totalPrice -= Number(item.price) * Number(item.goodsNumber);
      //         that.paidPrice -= Number(item.discountPrice) * Number(item.goodsNumber);
      //         that.sum -= Number(item.goodsNumber);
      //       });
      //       that.$set(taocanList[index].list[inIndex + 1], "totalAmount", 0);
      //       that.$set(
      //         taocanList[index].list[inIndex + 1],
      //         "checked",
      //         !checkedDef
      //       );
      //     } else {
      //       if (taocanList[index].list[inIndex - 1].checked == !checkedDef) {
      //         return false;
      //       }
      //       taocanList[index].list[inIndex - 1].list.map((item) => {
      //         that.ids.forEach((ite, i) => {
      //           if (ite == item.schemeId) {
      //             that.ids.splice(i, 1);
      //             that.number.splice(i, 1);
      //           }
      //         });
      //         that.totalPrice -= Number(item.price) * Number(item.goodsNumber);
      //         that.paidPrice -= Number(item.discountPrice) * Number(item.goodsNumber);
      //         that.sum -= Number(item.goodsNumber);
      //       });
      //       that.$set(taocanList[index].list[inIndex - 1], "totalAmount", 0);
      //       that.$set(
      //         taocanList[index].list[inIndex - 1],
      //         "checked",
      //         !checkedDef
      //       );
      //     }
      //   } else {
      //     that.img[index] = [];
      //     inItem.list.map((item) => {
      //       that.ids.forEach((ite, i) => {
      //         if (ite == item.schemeId) {
      //           that.ids.splice(i, 1);
      //           that.number.splice(i, 1);
      //         }
      //       });
      //       that.totalPrice -= Number(item.price) * Number(item.goodsNumber);
      //       that.paidPrice -= Number(item.discountPrice) * Number(item.goodsNumber);
      //       that.sum -= Number(item.goodsNumber);
      //     });
      //     inItem.totalAmount = 0;
      //   }
      // }
    },
    //丰富区选择
    FengFuChecked(item) {
      const that = this;
      if (item.scheme_id) {
        if (item.checked == true) {
          that.ids.push(item.scheme_id);
          that.number.push(item.goods_number);
          that.img.push([
            { scheme_id: item.scheme_id, imgUrl: item.goods_img },
          ]);
          that.totalPrice += Number(item.price);
          that.paidPrice += Number(item.discountPrice)
          that.sum++;
        } else {
          that.img.forEach((ite, i) => {
            if (ite.length > 0) {
              if (ite[0].scheme_id == item.scheme_id) {
                that.img.splice(i, 1);
              }
            }
          });
          that.ids.forEach((ite, i) => {
            if (ite == item.scheme_id) {
              that.ids.splice(i, 1);
              that.number.splice(i, 1);
            }
          });
          that.totalPrice -= Number(item.price);
          that.paidPrice -= Number(item.discountPrice)
          that.sum--;
        }
      } else {
        if (item.checked == true) {
          that.img.push([
            { goods_id: item.goods_id, imgUrl: item.goods_img },
          ]);
          that.totalPrice += Number(item.price);
          that.paidPrice += Number(item.discountPrice)
          that.sum += Number(item.goods_number);
          that.goodsList.push({
            goods_id: item.goods_id,
            goods_number: item.goods_number,
            goods_parameter: item.goods_parameter,
            parameters: item.parameters,
            goods_type: item.goods_type,
            price: item.sale_price,
            goods_name: item.goods_name,
            goods_img: item.file_path
          })
        } else {
          that.img.forEach((ite, i) => {
            if (ite.length > 0) {
              if (ite[0].goods_id == item.goods_id) {
                that.img.splice(i, 1);
              }
            }
          });
          that.goodsList.forEach((ite, i) => {
            if (ite.goodsId == item.goods_id) {
              that.goodsList.splice(i, 1);
            }
          });
          that.totalPrice -= Number(item.price);
          that.paidPrice -= Number(item.discountPrice)
          that.sum -= Number(item.goods_number);
        }
      }
    },
    //套餐详情数据
    packTopDetails() {
      const that = this;
      that.$api
        .setmealDetails({
          setmeal_id: that.setmeal_id,
        })
        .then((res) => {
          if (res.status == 100) {
            if (res.data.team_img) {
              that.homeSlide.push({ imgUrl: res.data.team_img });
              that.coverImg = res.data.team_img;
            }
            that.homeSlide.push({ imgUrl: res.data.lroom_img });
            that.homeSlide.push({ imgUrl: res.data.restaurant_img });
            that.homeSlide.push({ imgUrl: res.data.mroom_img });
            that.homeSlide.push({ imgUrl: res.data.bedroom_img });
            that.topDetails = res.data;
            that.coverImg = res.data.lroom_img;
          }
        });
    },
    //套餐产品详情
    packDetails() {
      const _self = this;
      _self.$api
        .packDetails({
          setmeal_id: _self.setmeal_id,
          type: 131,
        })
        .then((res) => {
          let fuList = res.data.slice(8)[0];
          console.log("fuList", fuList);
          for (let i = 0; i < fuList.length; i++) {
            for (let j = 0; j < fuList[i].length; j++) {
              for (let t = 0; t < fuList[i][j].length; t++) {
                fuList[i][j][t].checked = false;
                _self.fengFuList.push(fuList[i][j][t]);
              }
            }
          }
          console.log("_self.fengFuList", _self.fengFuList);
        });
    },
    //套餐产品详情
    mappPackDetails() {
      const _self = this;
      _self.$api
        .mallSetmealList({
          setmealId: _self.setmeal_id,
        })
        .then((res) => {
          console.log(res);
          _self.taocanList = res.data;
          _self.initData();
        });
    },
    //初始数据
    initData() {
      const that = this;
      let taocanList = that.taocanList;
      for (let i = 0; i < taocanList.length; i++) {
        that.img[i] = Array();
        for (let j = 0; j < taocanList[i].list.length; j++) {
          for (let t = 0; t < taocanList[i].list[j].list.length; t++) {
            if (i == 0 || i == 2 || i == 3) {
              // that.$set(taocanList[i].list[0], "checked", true);
              if (taocanList[i].list[j].checked == true) {
                that.img[i].push({
                  scheme_id: taocanList[i].list[j].list[t].schemeId,
                  imgUrl: taocanList[i].list[j].list[t].goodsImg,
                });
                that.ids.push(taocanList[i].list[j].list[t].schemeId);
                that.number.push(taocanList[i].list[j].list[t].goodsNumber);
                taocanList[i].list[j].totalAmount += Number(
                  taocanList[i].list[j].list[t].goodsNumber
                );
                that.totalPrice +=
                  Number(taocanList[i].list[j].list[t].price) *
                  Number(taocanList[i].list[j].list[t].goodsNumber);
                that.sum += Number(taocanList[i].list[j].list[t].goodsNumber);
                that.paidPrice +=
                  Number(taocanList[i].list[j].list[t].discountPrice) *
                  Number(taocanList[i].list[j].list[t].goodsNumber);
              }
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/style.scss";

@media (max-width: 1200px) {
  .package_cont {
    .package_deails {
      padding: 0 4%;

      .package_img {
        .package_left {
          padding-left: 0;
          width: 100%;

          img {
            width: 100%;
          }

          padding-right: 0 !important;
        }

        .package_right {
          display: flex !important;
          flex-direction: row;
          justify-content: space-between !important;
          padding-left: 0;
          padding-right: 0;
          align-items: flex-end;
          // border-left: 0.0625rem solid #9fa0a0;
          padding-top: 1rem;

          img {
            width: 24%;
            cursor: pointer;
            object-fit: cover;
          }
        }
      }

      .package_txt {
        margin-top: 4.56rem;
        margin-bottom: 2.13rem;

        .package_txt_left {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;

          .package_txt_title {
            font-size: 1.69rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #221815;
          }

          .package_txt_area {
            font-size: 0.91rem;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #221815;
            padding-left: 1.75rem;
          }

          .door_model {
            font-size: 0.91rem;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #221815;
            padding-left: 0.88rem;
          }
        }

        .package_txt_right {
          display: flex;
          margin-top: 2.88rem;

          .package_txt_details {
            font-size: 1.04rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #221815;
            text-align: left;
            padding-right: 0;
            padding-left: 0;
          }

          .package_plan {
            display: flex;
            padding-right: 0;
            padding-left: 0;
            // justify-content: flex-end;
            padding-top: 2rem;

            .erW {
              margin-right: 0.81rem;
              cursor: pointer;

              .erW_in {
                padding: 0.56rem;
                background: #f39800;
                display: inline-block;
                border-radius: 0.31rem;
                display: flex;
                align-items: center;

                span {
                  font-size: 1.17rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 1.5rem;
                }

                img {
                  width: 1.44rem;
                  height: 1.5rem;
                  margin-left: 0.38rem;
                }
              }
            }

            .sanW {
              cursor: pointer;

              .sanW_in {
                padding: 0.56rem;
                background: #f39800;
                display: inline-block;
                border-radius: 0.31rem;
                display: flex;
                align-items: center;

                span {
                  font-size: 1.17rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 1.5rem;
                }

                img {
                  width: 1.44rem;
                  height: 1.5rem;
                  margin-left: 0.38rem;
                }
              }

              .Qr_code {
                position: absolute;
                top: 3.875rem;
                left: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                span {
                  display: inline-block;
                  width: 0;
                  height: 0;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-bottom: 20px solid #ffffff;
                }

                img {
                  width: 7.875rem;
                  // height: 8.875rem;
                  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.3);
                }
              }
            }
          }
        }
      }

      .special_election {
        .special_item {
          padding-top: 3rem;

          .special_top {
            display: flex;
            align-items: center;
            padding-bottom: 1rem;
            border-bottom: 0.06rem solid #c9caca;
            margin-bottom: 0.57rem;

            img {
              width: 1.69rem;
              height: 1.31rem;
            }

            .space {
              font-size: 1.3rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #221815;
              padding: 0 0.81rem;
            }

            .choose {
              display: inline-block;
              font-size: 0.91rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              padding: 0.56rem 1.31rem;
              background: #f39800;
              border-radius: 0.31rem;
            }
          }

          .special_cont {
            padding: 1.56rem 2.44rem 2.06rem 2.44rem;
            background: #f2f2f2;
            margin-top: 1.31rem;

            .special_cont_tar {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .tar_name {
                display: flex;
                align-items: center;

                .space_one {
                  font-size: 1.04rem;
                  font-family: Source Han Sans SC;
                  font-weight: bold;
                  color: #595757;
                  padding: 0 1.25rem 0 0.5rem;
                  border-left: 0.13rem solid #595757;
                }

                .choose_num {
                  display: inline-block;
                  font-size: 0.91rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #ffffff;
                  padding: 0.56rem 1.31rem;
                  background: #f39800;
                  border-radius: 0.31rem;
                }
              }
            }

            .goods_list {
              display: flex;
              flex-wrap: wrap;

              .goods_item:nth-child(2n) {
                margin-right: 0;
              }

              .goods_item {
                width: 48%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-top: 1.44rem;
                margin-right: 4%;
                cursor: pointer;

                img {
                  width: 100%;
                  // height: 10.94rem;
                }

                .goods_amount {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding-top: 1.19rem;
                  width: 100%;

                  .goods_name {
                    font-size: 1.04rem;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #3e3a39;
                  }

                  .goods_num {
                    display: flex;
                    align-items: center;
                    border: 0.06rem solid #555555;

                    .reduce {
                      display: inline-block;
                      width: 1.13rem;
                      height: 1.13rem;
                      background: #f8f8f8;
                      font-size: 1.04rem;
                      font-family: Source Han Sans SC;
                      font-weight: 400;
                      color: #3e3a39;
                      text-align: center;
                      line-height: 1.13rem;
                      border-right: 0.06rem solid #555555;
                      cursor: pointer;
                      -moz-user-select: none;
                      /*火狐*/
                      -webkit-user-select: none;
                      /*webkit浏览器*/
                      -ms-user-select: none;
                      /*IE10*/
                      -khtml-user-select: none;
                      /*早期浏览器*/
                      -o-user-select: none;
                      /* Opera*/
                      user-select: none;
                    }

                    .num {
                      display: inline-block;
                      width: 1.88rem;
                      height: 1.13rem;
                      background: #f8f8f8;
                      font-size: 0.91rem;
                      font-family: Source Han Sans SC;
                      font-weight: 400;
                      color: #3e3a39;
                      text-align: center;
                      line-height: 1.13rem;
                    }

                    .add {
                      display: inline-block;
                      width: 1.13rem;
                      height: 1.13rem;
                      background: #555555;
                      font-size: 1.04rem;
                      font-family: Source Han Sans SC;
                      font-weight: 400;
                      color: #ffffff;
                      text-align: center;
                      line-height: 1.13rem;
                      cursor: pointer;
                      -moz-user-select: none;
                      /*火狐*/
                      -webkit-user-select: none;
                      /*webkit浏览器*/
                      -ms-user-select: none;
                      /*IE10*/
                      -khtml-user-select: none;
                      /*早期浏览器*/
                      -o-user-select: none;
                      /* Opera*/
                      user-select: none;
                    }
                  }
                }

                .price {
                  padding-top: 0.94rem;

                  .bar_price {
                    font-size: 0.91rem;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #3e3a39;
                    text-decoration: line-through;
                  }

                  .preferential_price {
                    font-size: 0.91rem;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #e60012;
                    padding-left: 0.88rem;
                  }
                }

                .model {
                  font-size: 0.78rem;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  color: #727171;
                  padding-top: 1.3rem;
                  text-align: left;
                }

                .size {
                  font-size: 0.78rem;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  color: #727171;
                  padding-top: 0.63rem;
                  text-align: left;
                }

                .material {
                  font-size: 0.78rem;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  color: #727171;
                  padding-top: 0.63rem;
                  text-align: left;
                }
              }
            }
          }
        }
      }

      .FengFu_district {
        padding: 2.31rem 2.44rem 2.5rem 2.44rem;
        background: #f2f2f2;
        margin-top: 5.25rem;

        .FengFu_title {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 1.81rem;

          .line_left {
            display: inline-block;
            width: 6.25rem;
            height: 0.13rem;
            background: #898989;
          }

          .FengFu_name {
            font-size: 1.56rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #595757;
            margin: 0 1.44rem;
          }

          .line_right {
            display: inline-block;
            width: 6.25rem;
            height: 0.13rem;
            background: #898989;
          }
        }

        .goods_list {
          display: flex;
          flex-wrap: wrap;

          .goods_item:nth-child(2n) {
            margin-right: 0;
          }

          .goods_item {
            width: 48%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 1.44rem;
            margin-right: 4%;

            img {
              width: 100%;
              // height: 10.94rem;
            }

            .goods_amount {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-top: 1.19rem;
              width: 100%;

              .goods_name {
                font-size: 1.04rem;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #3e3a39;
              }

              .goods_num {
                display: flex;
                align-items: center;
                border: 0.06rem solid #555555;

                .reduce {
                  display: inline-block;
                  width: 1.13rem;
                  height: 1.13rem;
                  background: #f8f8f8;
                  font-size: 1.04rem;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  color: #3e3a39;
                  text-align: center;
                  line-height: 1.13rem;
                  border-right: 0.06rem solid #555555;
                  cursor: pointer;
                }

                .num {
                  display: inline-block;
                  width: 1.88rem;
                  height: 1.13rem;
                  background: #f8f8f8;
                  font-size: 0.91rem;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  color: #3e3a39;
                  text-align: center;
                  line-height: 1.13rem;
                }

                .add {
                  display: inline-block;
                  width: 1.13rem;
                  height: 1.13rem;
                  background: #555555;
                  font-size: 1.04rem;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  color: #ffffff;
                  text-align: center;
                  line-height: 1.13rem;
                  cursor: pointer;
                }
              }
            }

            .price {
              padding-top: 0.94rem;

              .bar_price {
                font-size: 0.91rem;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #3e3a39;
                text-decoration: line-through;
              }

              .preferential_price {
                font-size: 0.91rem;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #e60012;
                padding-left: 0.88rem;
              }
            }

            .model {
              font-size: 0.78rem;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #727171;
              padding-top: 1.3rem;
              text-align: left;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .size {
              font-size: 0.78rem;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #727171;
              padding-top: 0.63rem;
              text-align: left;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .material {
              font-size: 0.78rem;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #727171;
              padding-top: 0.63rem;
              text-align: left;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .add_goods {
          margin-top: 3.25rem;

          span {
            display: inline-block;
            font-size: 1.17rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            padding: 0.75rem 1.5rem;
            background: #f39800;
            border-radius: 0.31rem;
            cursor: pointer;
          }
        }
      }
    }

    .package_bottom {
      width: 100%;
      position: sticky;
      bottom: 0;
      z-index: 99;

      .look_img {
        padding: 0.25rem 1.25rem 1.25rem 1.25rem;
        background: #969696;
        position: relative;

        .img_list {
          display: flex;
          flex-wrap: wrap;

          .img_item {
            cursor: pointer;
            position: relative;

            .img_item_bar {
              padding-right: 0.57rem;

              .goods_img {
                width: 6.25rem;
                height: 4.5rem;
                padding-right: 0.31rem;
                margin-top: 1rem;
              }

              .goods_del {
                width: 1rem;
                height: 1.13rem;
                position: absolute;
                top: 0.9375rem;
                right: 0.88rem;
              }
            }
          }
        }

        .look_Img_Hid {
          width: 1rem;
          height: 1.13rem;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }

      .total_price_information {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;

        .number {
          padding-left: 0.44rem;
          cursor: pointer;

          img {
            width: 1.25rem;
            height: 0.69rem;
            padding-right: 0.19rem;
          }

          .number_txt {
            font-size: 0.91rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3e3a39;
          }

          .num {
            font-size: 0.91rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #f39800;
          }
        }

        .original {
          display: flex;
          flex-direction: column;
          align-items: center;

          .original_name {
            font-size: 0.91rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3e3a39;
          }

          .original_price {
            font-size: 1rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3e3a39;
          }
        }

        .preferential {
          display: flex;
          flex-direction: column;
          align-items: center;

          .preferential_name {
            font-size: 0.91rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3e3a39;
          }

          .preferential_price {
            font-size: 1rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #e60012;
          }
        }

        .actual {
          display: flex;
          flex-direction: column;
          align-items: center;

          .actual_name {
            font-size: 0.91rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3e3a39;
          }

          .actual_price {
            font-size: 1rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #e50012;
          }
        }

        .operation {
          .export_list {
            display: inline-block;
            font-size: 0.6rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            width: 5.38rem;
            height: 3.06rem;
            background: #4b4b4b;
            text-align: center;
            line-height: 3.06rem;
          }

          .Submit_orders {
            display: inline-block;
            font-size: 0.6rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            width: 5.38rem;
            height: 3.06rem;
            background: #f39800;
            text-align: center;
            line-height: 3.06rem;
          }
        }
      }
    }
  }
}

.row {
  margin-right: 0;
  margin-left: 0;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f39800;
  border-color: #f39800;
}

/deep/.el-checkbox__inner:hover {
  border-color: #f39800;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: #f39800;
}

@media (min-width: 1200px) {
  .package {
    display: flex;
    justify-content: center;
    background: #ffffff;

    .package_cont {
      width: 70%;

      .package_deails {
        .package_img {
          .package_left {
            padding-left: 0;
            width: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              padding-top: 10px;
            }

            padding-right: 2.13rem;
          }

          .package_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 0;
            padding-right: 0;
            align-items: flex-end;
            border-left: 0.0625rem solid #9fa0a0;

            img {
              width: 16.5rem;
              cursor: pointer;
              height: 9rem;
              object-fit: cover;
              padding-top: 10px;
            }
          }
        }

        .package_txt {
          margin-top: 4.56rem;
          margin-bottom: 2.13rem;

          .package_txt_left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            .package_txt_title {
              font-size: 1.69rem;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #221815;
            }

            .package_txt_area {
              font-size: 0.91rem;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #221815;
              padding-left: 1.75rem;
            }

            .door_model {
              font-size: 0.91rem;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #221815;
              padding-left: 0.88rem;
            }
          }

          .package_txt_right {
            display: flex;
            margin-top: 2.88rem;

            .package_txt_details {
              font-size: 1.04rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #221815;
              text-align: left;
              padding-right: 0;
              padding-left: 0;
            }

            .package_plan {
              display: flex;
              padding-right: 0;
              padding-left: 0;
              justify-content: flex-end;

              .erW {
                margin-right: 0.81rem;
                cursor: pointer;

                .erW_in {
                  padding: 0.56rem;
                  background: #f39800;
                  display: inline-block;
                  border-radius: 0.31rem;
                  display: flex;
                  align-items: center;

                  span {
                    font-size: 1.17rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 1.5rem;
                  }

                  img {
                    width: 1.44rem;
                    height: 1.5rem;
                    margin-left: 0.38rem;
                  }
                }
              }

              .sanW {
                cursor: pointer;
                position: relative;

                .sanW_in {
                  padding: 0.56rem;
                  background: #f39800;
                  display: inline-block;
                  border-radius: 0.31rem;
                  display: flex;
                  align-items: center;

                  span {
                    font-size: 1.17rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 1.5rem;
                  }

                  img {
                    width: 1.44rem;
                    height: 1.5rem;
                    margin-left: 0.38rem;
                  }
                }

                .Qr_code {
                  position: absolute;
                  top: 1.875rem;
                  left: 0;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;

                  span {
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 20px solid #ffffff;
                  }

                  img {
                    width: 8.875rem;
                    // height: 8.875rem;
                    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.3);
                  }
                }
              }
            }
          }
        }

        .special_election {
          .special_item {
            padding-top: 3rem;

            .special_top {
              display: flex;
              align-items: center;
              padding-bottom: 1rem;
              border-bottom: 0.06rem solid #c9caca;
              margin-bottom: 0.57rem;

              img {
                width: 1.69rem;
                height: 1.31rem;
              }

              .space {
                font-size: 1.3rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #221815;
                padding: 0 0.81rem;
              }

              .choose {
                display: inline-block;
                font-size: 0.91rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
                padding: 0.56rem 1.31rem;
                background: #f39800;
                border-radius: 0.31rem;
              }
            }

            .special_cont_wrap {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            }

            .special_cont_one{
              width:100%;
              padding: 1.56rem 2.44rem 2.06rem 2.44rem;
              background: #f2f2f2;
              margin-top: 1.31rem;
            }

            .special_cont {
              width: 48%;
              padding: 1.56rem 2.44rem 2.06rem 2.44rem;
              background: #f2f2f2;
              margin-top: 1.31rem;
            }
              .special_cont_tar {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .tar_name {
                  display: flex;
                  align-items: center;

                  .space_one {
                    font-size: 1.04rem;
                    font-family: Source Han Sans SC;
                    font-weight: bold;
                    color: #595757;
                    padding: 0 1.25rem 0 0.5rem;
                    border-left: 0.13rem solid #595757;
                  }

                  .choose_num {
                    display: inline-block;
                    font-size: 0.91rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #ffffff;
                    padding: 0.56rem 1.31rem;
                    background: #d19d7e;
                    border-radius: 0.31rem;
                  }
                }
              }

              .goods_list {
                display: flex;
                flex-wrap: wrap;
                // justify-content: space-between;

                .goods_item:nth-child(4n) {
                  margin-right: 0;
                }

                .goods_item {
                  width: 16.56rem;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding-top: 1.44rem;
                  // margin-right: 1.25rem;
                  cursor: pointer;

                  .pic {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 16.875rem;
                    background-color: #fff;
                  }

                  img {
                    width: 16.56rem;
                    height: 10.94rem;
                  }

                  .goods_amount {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-top: 1.19rem;
                    width: 100%;

                    .goods_name {
                      font-size: 1.04rem;
                      font-family: Source Han Sans SC;
                      font-weight: 400;
                      color: #3e3a39;
                    }

                    .goods_num {
                      display: flex;
                      align-items: center;
                      border: 0.06rem solid #555555;

                      .reduce {
                        display: inline-block;
                        width: 1.13rem;
                        height: 1.13rem;
                        background: #f8f8f8;
                        font-size: 1.04rem;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #3e3a39;
                        text-align: center;
                        line-height: 1.13rem;
                        border-right: 0.06rem solid #555555;
                        cursor: pointer;
                        -moz-user-select: none;
                        /*火狐*/
                        -webkit-user-select: none;
                        /*webkit浏览器*/
                        -ms-user-select: none;
                        /*IE10*/
                        -khtml-user-select: none;
                        /*早期浏览器*/
                        -o-user-select: none;
                        /* Opera*/
                        user-select: none;
                      }

                      .num {
                        display: inline-block;
                        width: 1.88rem;
                        height: 1.13rem;
                        background: #f8f8f8;
                        font-size: 0.91rem;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #3e3a39;
                        text-align: center;
                        line-height: 1.13rem;
                      }

                      .add {
                        display: inline-block;
                        width: 1.13rem;
                        height: 1.13rem;
                        background: #555555;
                        font-size: 1.04rem;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #ffffff;
                        text-align: center;
                        line-height: 1.13rem;
                        cursor: pointer;
                        -moz-user-select: none;
                        /*火狐*/
                        -webkit-user-select: none;
                        /*webkit浏览器*/
                        -ms-user-select: none;
                        /*IE10*/
                        -khtml-user-select: none;
                        /*早期浏览器*/
                        -o-user-select: none;
                        /* Opera*/
                        user-select: none;
                      }
                    }
                  }

                  .price {
                    padding-top: 0.94rem;

                    .bar_price {
                      font-size: 0.91rem;
                      font-family: Source Han Sans SC;
                      font-weight: 400;
                      color: #3e3a39;
                      text-decoration: line-through;
                    }

                    .preferential_price {
                      font-size: 0.91rem;
                      font-family: Source Han Sans SC;
                      font-weight: 400;
                      color: #e60012;
                      padding-left: 0.88rem;
                    }
                  }

                  .model {
                    font-size: 0.78rem;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #727171;
                    padding-top: 1.3rem;
                    text-align: left;
                  }

                  .size {
                    font-size: 0.78rem;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #727171;
                    padding-top: 0.63rem;
                  }

                  .material {
                    font-size: 0.78rem;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #727171;
                    padding-top: 0.63rem;
                  }
                }

                .big {
                  // width: 100%;
                  // width: 16.56rem;
              display: flex;
              flex-direction: column;
              align-items: flex-start;               
              padding-top: 1.44rem;
              margin-right: 1.25rem;
              cursor: pointer;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            
            // .package_cont .package_deails .special_election .special_item .special_cont .goods_list .goods_item[data-v-4259639e] {
            //   width: 50%;
            // }
            // .special_item>.special_cont:first-child{
            //   width: 100%;
            // }
            
            // .package .package_cont .package_deails .special_election .special_item .special_cont[data-v-4259639e]{
            //   width:100%
            // }
          }
        }

        .FengFu_district {
          padding: 2.31rem 2.44rem 2.5rem 2.44rem;
          background: #f2f2f2;
          margin-top: 5.25rem;
          margin-bottom: 2.5rem;

          .FengFu_title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 1.81rem;

            .line_left {
              display: inline-block;
              width: 6.25rem;
              height: 0.13rem;
              background: #898989;
            }

            .FengFu_name {
              font-size: 1.56rem;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #595757;
              margin: 0 1.44rem;
            }

            .line_right {
              display: inline-block;
              width: 6.25rem;
              height: 0.13rem;
              background: #898989;
            }
          }

          .goods_list {
            display: flex;
            flex-wrap: wrap;

            .goods_item:nth-child(4n) {
              margin-right: 0;
            }

            .goods_item {
              width: 16.56rem;
              display: flex;
              flex-direction: column;
              align-items: flex-start;               
              padding-top: 1.44rem;
              margin-right: 1.25rem;
              cursor: pointer;

              img {
                width: 16.56rem;
                height: 10.94rem;
              }

              .goods_amount {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 1.19rem;
                width: 100%;

                .goods_name {
                  font-size: 1.04rem;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  color: #3e3a39;
                }

                .goods_num {
                  display: flex;
                  align-items: center;
                  border: 0.06rem solid #555555;

                  .reduce {
                    display: inline-block;  
                    width: 1.13rem;
                    height: 1.13rem;
                    background: #f8f8f8;
                    font-size: 1.04rem;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #3e3a39;
                    text-align: center;
                    line-height: 1.13rem;
                    border-right: 0.06rem solid #555555;
                    cursor: pointer;
                  }

                  .num {
                    display: inline-block;
                    width: 1.88rem;
                    height: 1.13rem;
                    background: #f8f8f8;
                    font-size: 0.91rem;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #3e3a39;
                    text-align: center;
                    line-height: 1.13rem;
                  }

                  .add {
                    display: inline-block;
                    width: 1.13rem;
                    height: 1.13rem;
                    background: #555555;
                    font-size: 1.04rem;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    line-height: 1.13rem;
                    cursor: pointer;
                  }
                }
              }

              .price {
                padding-top: 0.94rem;

                .bar_price {
                  font-size: 0.91rem;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  color: #3e3a39;
                  text-decoration: line-through;
                }

                .preferential_price {
                  font-size: 0.91rem;
                  font-family: Source Han Sans SC;
                  font-weight: 400;
                  color: #e60012;
                  padding-left: 0.88rem;
                }
              }

              .model {
                font-size: 0.78rem;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #727171;
                padding-top: 1.3rem;
                text-align: left;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .size {
                font-size: 0.78rem;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #727171;
                padding-top: 0.63rem;
                text-align: left;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .material {
                font-size: 0.78rem;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #727171;
                padding-top: 0.63rem;
                text-align: left;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .add_goods {
            margin-top: 3.25rem;

            span {
              display: inline-block;
              font-size: 1.17rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              padding: 0.75rem 1.5rem;
              background: #f39800;
              border-radius: 0.31rem;
              cursor: pointer;
            }
          }
        }
      }

      .package_bottom {
        width: 100%;
        position: sticky;
        bottom: 0;
        z-index: 99;

        .look_img {
          padding: 0.25rem 1.25rem 1.25rem 1.25rem;
          background: #969696;
          position: relative;

          .img_list {
            display: flex;
            flex-wrap: wrap;

            .img_item {
              cursor: pointer;
              position: relative;

              .img_item_bar {
                padding-right: 0.57rem;

                .goods_img {
                  width: 6.25rem;
                  height: 4.5rem;
                  padding-right: 0.31rem;
                  margin-top: 1rem;
                }

                .goods_del {
                  width: 1rem;
                  height: 1.13rem;
                  position: absolute;
                  top: 0.9375rem;
                  right: 0.88rem;
                }
              }
            }
          }

          .look_Img_Hid {
            width: 1rem;
            height: 1.13rem;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
          }
        }

        .total_price_information {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #ffffff;

          .number {
            padding-left: 2.44rem;
            cursor: pointer;

            img {
              width: 1.25rem;
              height: 0.69rem;
              padding-right: 0.19rem;
            }

            .number_txt {
              font-size: 0.91rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3e3a39;
            }

            .num {
              font-size: 0.91rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #f39800;
            }
          }

          .original {
            .original_name {
              font-size: 0.91rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3e3a39;
            }

            .original_price {
              font-size: 1.3rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3e3a39;
            }
          }

          .preferential {
            .preferential_name {
              font-size: 0.91rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3e3a39;
            }

            .preferential_price {
              font-size: 1.3rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #e60012;
            }
          }

          .actual {
            .actual_name {
              font-size: 0.91rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3e3a39;
            }

            .actual_price {
              font-size: 1.3rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #e50012;
            }
          }

          .operation {
            .export_list {
              display: inline-block;
              font-size: 1.17rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              width: 9.38rem;
              height: 4.06rem;
              background: #4b4b4b;
              text-align: center;
              line-height: 4.06rem;
            }

            .Submit_orders {
              display: inline-block;
              font-size: 1.17rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              width: 9.38rem;
              height: 4.06rem;
              background: #f39800;
              text-align: center;
              line-height: 4.06rem;
            }
          }
        }
      }
    }
  }
}
</style>